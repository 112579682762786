export const iconLinks = [
	{
		link: "https://t.me/d1sinterested",
		iconStyleName: "fab fa-telegram-plane",
	},
	{
		link: "https://vk.com/dis1nterested",
		iconStyleName: "fa-brands fa-vk",
	},
	{
		link: "mailto:sharfull33@gmail.ru",
		iconStyleName: "far fa-envelope",
	},
	{
		link: "https://github.com/stormrage-web",
		iconStyleName: "fa-brands fa-github",
	},
];
